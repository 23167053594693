@font-face {
	font-family: "Montserrat";
	src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
	font-weight: lighter;
	font-style: normal;
}

html,
body {
	font-family: Montserrat !important;
	background-color: #ffffff !important;
	font-weight: normal !important;
	margin: 0;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
	display: none;
}

.hover-opacity:hover {
	opacity: 0.9;
	cursor: pointer;
}

#root {
	height: 100%;
}

p {
	font-style: normal;
}

h1 {
	font-style: normal;
	font-weight: bold !important;
	font-size: 36px;
	line-height: 44px;
	/* identical to box height */

	color: #000000;
}

/* new new new */

.second-row {
	min-height: 638px;
	background: #f2f2f2 0% 0% no-repeat padding-box;
	position: relative;
}

.second-row .title {
	font-weight: Montserrat-Bold !important;
	font-weight: bold !important;
	font-size: 36px;
	line-height: 43px;
	color: #000000;
	max-width: 900px;
	text-align: center;
	white-space: pre-line;
}

.second-row .logos {
	margin-top: 49px;
}

.second-row .logo-item {
	width: 160px;
	height: 110px;
	mix-blend-mode: multiply;
}

/* home fourth */
.fourth-row {
	min-height: 389px;
	background-color: #0055ff;
}

/* home solutions */

.home-solutions {
	margin-top: 160px;
	margin-bottom: 160px;
	position: relative;
	overflow: hidden;
}

.home-solutions .cards {
	margin-top: 90px;
}

.home-solutions .how-it-works,
.home-solutions .system {
	margin-top: 190px;
}

.home-solutions .how-it-works .descriptive-item .title {
	margin-bottom: 140px;
}

.home-solutions .functions {
	margin-top: 190px;
}

.home-solutions .bg-bubble {
	top: 2300px;
	right: -100px;
	width: 1600px;
	height: 1600px;
	background: #f2f2f2 0% 0% no-repeat padding-box;
	border-radius: 800px;
	z-index: -1;
}

/* home review row */

.home-review-row {
	height: 607px;
	background: #f2f2f2 0% 0% no-repeat padding-box;
}
.home-review-row .title {
	max-width: 1460px;
	font-size: 48px;
	font-weight: lighter;
	text-align: center;
}
.home-review-row span {
	font-weight: bold;
}
.home-review-row .subtitle {
	margin-top: 92px;
	text-align: center;
}

/* home first */
.home-first {
	overflow: hidden;
	position: relative;
	height: 900px;
}

.home-first .subscribtion .title {
	text-align: left;
	color: #000;
	font-weight: bold;
	font-size: 48px;
	line-height: 57px;
}

.home-first .subscribtion ul {
	margin-top: 32px;
	margin-bottom: 32px;
	list-style-image: url("./assets/tick_blue.svg");
	color: #000;
	text-align: left;
	font-size: 26px;
	line-height: 34px;
	font-weight: lighter;
	font-size: 22px;
}

.home-first .audit {
	top: 60px;
	width: 235px;
	height: 235px;
	margin-left: 260px;
	z-index: 2;
}

.home-first .blue-bg {
	top: 25px;
	right: -375px;
	width: 1100px;
	height: 1100px;
	background: #0055ff 0% 0% no-repeat padding-box;
	border-radius: 550px;
	z-index: 0;
}

.home-first .image {
	right: -375px;
	z-index: 1;
}

/* home third row */

.home-third-row {
	overflow: hidden;
	position: relative;
	min-height: 900px;
}

.home-third-row .subscribtion .title {
	text-align: left;
	color: #000;
	font-weight: bold;
	font-size: 47px;
	line-height: 57px;
}

.home-third-row .subscribtion .subtitle {
	text-align: left;
	color: #000;
	font-weight: lighter;
	font-size: 22px;
	line-height: 34px;
}

.home-third-row .audit {
	top: 60px;
	width: 235px;
	height: 235px;
	margin-left: 260px;
}

.home-third-row .blue-bg {
	position: absolute;
	top: -575px;
	left: -450px;
	width: 1100px;
	height: 1100px;
	background: #0055ff 0% 0% no-repeat padding-box;
	border-radius: 550px;
	z-index: -1;
}

.home-third-row .image img {
	z-index: 1;
}

/* home costs */

.home-costs {
	margin-top: 160px;
	margin-bottom: 0px;
}

.home-costs .subscribtion {
	max-width: 690px;
}

.home-costs .subscribtion ul {
	margin-top: 44px;
	list-style-image: url("./assets/tick_blue.svg");
	color: #0055ff;
	text-align: left;
	font-size: 26px;
	line-height: 52px;
}

.home-costs .subscribtion .title {
	text-align: left;
	color: #0055ff;
	font-size: 56px;
	line-height: 67px;
}

/* home try row */
.home-try-row {
	margin-bottom: 60px;
	color: white;
}

.home-try-row .bg {
	border-radius: 60px;
	background-color: #0055ff;
	min-height: 389px;
}

.home-try-row .title {
	font-weight: bold;
	font-size: 48px;
}
.home-try-row .subtitle {
	font-weight: lighter;
	font-size: 18px;
}

/* generic */

/* button common */
.button-common {
	color: #fff;
	background-color: #0055ff;
	font-weight: bold;
	border-radius: 100px;
}

/* stats-item */
.stats-item .title {
	font-weight: bold;
	color: #fff;
	font-size: 91px;
	letter-spacing: 0.91px;
	line-height: 110px;
}

.stats-item .subtitle {
	color: #fff;
	font-size: 20px;
	letter-spacing: 0.4px;
	line-height: 16px;
}

/* descriptive-item */
.descriptive-item {
	max-width: 812px;
}

.descriptive-item .title {
	font-size: 48px;
	line-height: 57px;
	font-weight: bold;
	text-align: center;
}

.descriptive-item .subtitle {
	font-size: 22px;
	line-height: 34px;
	text-align: center;
	margin-top: 28px;
}

/* solution card */

.solution-card {
	width: 616px;
	height: 842px;
	background: #fafafa 0% 0% no-repeat padding-box;
	border-radius: 83px;
	padding: 32px;
	margin-left: 64px;
	margin-right: 64px;
}

.solution-card .title {
	font-size: 36px;
	line-height: 43px;
	font-weight: bold;
	text-align: center;
}

.solution-card .subtitle {
	font-size: 22px;
	line-height: 34px;
	text-align: center;
	margin-top: 32px;
}

/* how it works item */

.how-it-works-item {
	font-weight: lighter;
}

.how-it-works-item .image {
	width: 256px;
	height: 256px;
	border-radius: 128px;
	background: #0055ff 0% 0% no-repeat padding-box;
}

.how-it-works-item img {
	object-fit: contain;
}

.how-it-works-item .title {
	margin-top: 45px;
	width: 192px;
	text-align: center;
}

/* system item */
.system-item {
	font-weight: lighter;
	font-size: 28px;
	line-height: 34px;
}

/* function item */
.function-item {
	margin-top: 90px;
}

.function-item .title {
	font-weight: lighter;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	max-width: 180px;
}

.function-item img {
	max-height: 70px;
	width: auto;
}

/* costs panel */

.costs-panel {
	margin-top: 160px;
	margin-bottom: 160px;
}

.costs-panel .title {
	font-weight: bold;
	font-size: 48px;
	text-align: left;
	line-height: 57px;
	max-width: 728px;
}

.costs-panel .summary {
	width: 538px;
	height: 953px;
	background: #0055ff 0% 0% no-repeat padding-box;
	border-radius: 60px;
	color: #fff;
	font-size: 22px;
	line-height: 34px;
}

.costs-panel .summary .total {
	font-weight: bold;
	border-top: #fff solid 1px;
	font-size: 36px;
	line-height: 43px;
}

.costs-panel .summary .button {
	background-color: #fff;
	color: #0055ff;
	width: 100%;
	height: 69px;
	border-radius: 35px;
	font-weight: bold;
	text-align: center;
}

.costs-panel .section-title {
	font-weight: bold;
	font-size: 38px;
	line-height: 48px;
}

/* cost panel item */

.cost-panel-item {
	max-width: 340px;
}

.cost-panel-item .title {
	font-weight: bold;
	font-size: 24px;
	text-align: left;
	line-height: 29px;
}

.cost-panel-item .subtitle {
	font-weight: lighter;
	font-size: 22px;
	text-align: left;
	line-height: 34px;
}

.cost-panel-item .input {
	width: 165px;
	height: 60px;
	border: 1px solid #0055ff;
	border-radius: 42px;
	opacity: 1;
	text-align: center;
	color: #0055ff;
}

.cost-panel-item .input img {
	width: 40px;
}

/* cost panel item  new*/

.cost-panel-item-new {
	border: 2px solid #0055ff;
	border-radius: 25px;
	padding: 0 10px 10px 0;
	color: black;
}

.cost-panel-item-new.selected {
	background-color: #0055ff;
	color: white;
}

.cost-panel-item-new .title {
	font-size: 18px;
	line-height: 21px;
}

.cost-panel-item-new .subtitle {
	font-size: 22px;
	line-height: 34px;
}

.cost-panel-item-new .input img {
	width: 40px;
}

/* nav bar */

.navigation-bar {
	background: #fff;
	height: 120px;
}

.navigation-bar .logo {
	width: 130px;
	height: auto;
}

.navigation-bar {
	font-weight: bold;
	color: #000;
}

/* email input */
.email-input {
	height: 64px;
	border-radius: 32px;
	border: 1px solid #fff;
	color: #0055ff;
}

.email-input input {
	background-color: transparent;
	box-sizing: unset;
	border: none;
	color: #fff;
	/* min-width: 240px; */
	height: 100%;
	font-size: 18px;
	font-weight: lighter;
}

.email-input input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #fff;
	opacity: 1; /* Firefox */
}

.email-input input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
}

.email-input input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #fff;
}

.email-input .button {
	background-color: #fff;
	height: 100%;
	width: 190px;
	border-top-right-radius: 32px;
	border-bottom-right-radius: 32px;
	font-weight: bold;
	text-align: center;
	vertical-align: middle;
	font-size: 18px;
}

.email-input .blue {
	background-color: #0055ff;
	color: #fff;
}

.email-input-white {
	color: #fff;
	border: 1px solid #0055ff;
}

.email-input-white input {
	color: #727272;
}

.email-input-white input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #727272;
	opacity: 1; /* Firefox */
}

.email-input-white input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #727272;
}

.email-input-white input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #727272;
}

/* ul white row */
ul.ul-white-row {
	display: inline-flex;
}
ul.ul-white-row li {
	list-style-type: circle;
	display: list-item;
	margin: 5px;
	padding: 2px;
	list-style-image: url("./assets/tick_white_ico.svg");
}
ul.ul-white-row span {
	display: inline;
	float: left;
	background-image: url("./assets/arrow_right.svg");
}

/* utils */
.myt-190 {
	margin-top: 190px;
}

/* break points */
@media (min-width: 576px) {
	.stats-item .title {
		font-size: 62px;
		line-height: 68px;
	}
}

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
	/* .home-review-row .title {
	}
	.home-first .subscribtion .title {
	}
	.home-try-row .title {
	}
	.costs-panel .title {
	}
	.descriptive-item .title {
	} */

	.myt-190 {
		margin-top: 0;
	}

	.second-row .title {
		font-size: 22px;
		line-height: 30px;
	}

	.second-row .logos {
		margin-top: 49px;
	}

	.second-row .logo-item {
		width: 80px;
		height: auto;
	}

	/* home fourth */
	.fourth-row {
		min-height: 389px;
		background-color: #0055ff;
	}

	/* home solutions */

	.home-solutions {
		margin-top: 80px;
		margin-bottom: 80px;
		position: relative;
		overflow: hidden;
	}

	.home-solutions .cards {
		margin-top: 90px;
	}

	.home-solutions .how-it-works,
	.home-solutions .system {
		margin-top: 90px;
	}

	.home-solutions .how-it-works .descriptive-item .title {
		margin-bottom: 40px;
	}

	.home-solutions .how-it-works .arrow img {
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		transform: rotate(90deg);
		width: 14px;
		height: 14px;
	}

	.home-solutions .functions {
		margin-top: 90px;
	}

	.home-solutions .bg-bubble {
		top: 3020px;
		left: -1300px;
		width: 1600px;
		height: 1600px;
		background: #f2f2f2 0% 0% no-repeat padding-box;
		border-radius: 800px;
		z-index: -1;
	}

	/* home review row */

	.home-review-row {
		height: auto;
		background: #f2f2f2 0% 0% no-repeat padding-box;
	}
	.home-review-row .title {
		max-width: auto;
		font-size: 24px;
		line-height: 30px;
	}
	.home-review-row span {
		font-weight: bold;
	}
	.home-review-row .subtitle {
		margin-top: 92px;
		text-align: center;
	}

	/* home first */
	.home-first {
		margin-top: 80px;
		margin-bottom: 80px;
		height: auto;
	}

	.home-first .subscribtion .title {
		text-align: left;
		color: #000;
		font-weight: bold;
		font-size: 32px;
		line-height: 38px;
	}

	.home-first .subscribtion ul {
		margin-top: 32px;
		margin-bottom: 32px;
		list-style-image: url("./assets/tick_blue.svg");
		color: #000;
		text-align: left;
		font-size: 14px;
		line-height: 20px;
		font-weight: lighter;
	}

	.home-first .audit {
		top: 60px;
		width: 235px;
		height: 235px;
		margin-left: 260px;
	}

	.home-first .blue-bg {
		top: 25px;
		right: -375px;
		width: 1100px;
		height: 1100px;
		background: #0055ff 0% 0% no-repeat padding-box;
		border-radius: 550px;
		z-index: 0;
	}

	.home-first .image {
		right: -375px;
		z-index: 1;
	}

	/* home third row */

	.home-third-row {
		overflow: hidden;
		position: relative;
		height: auto;
		min-height: auto;
	}

	.home-third-row .subscribtion .title {
		font-size: 22px;
		line-height: 30px;
	}

	.home-third-row .subscribtion .subtitle {
		font-size: 16px;
		line-height: 26px;
	}

	.home-third-row .blue-bg {
		top: -120px;
		left: -75px;
		width: 450px;
		height: 450px;
		border-radius: 250px;
		z-index: -1;
	}

	.home-third-row .image {
		position: relative;
		left: 0px;
		top: 0;
		width: 420px;
		height: 420px;
		z-index: 1;
	}

	/* home costs */

	.home-costs {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.home-costs .subscribtion {
		max-width: 690px;
	}

	.home-costs .subscribtion ul {
		margin-top: 20px;
		font-size: 14px;
		line-height: 36px;
	}

	.home-costs .subscribtion .title {
		font-size: 32px;
		line-height: 38px;
	}

	.home-costs .phone {
		width: 415px;
		left: -50px;
		position: relative;
	}

	/* home try row */

	.home-try-row .bg {
		border-radius: 40px;
	}

	.home-try-row .title {
		font-size: 22px;
		line-height: 30px;
	}
	.home-try-row .subtitle {
		font-size: 16px;
		line-height: 26px;
	}

	/* generic */

	/* stats-item */
	.stats-item .title {
		font-size: 32px;
		line-height: 38px;
		text-align: center;
	}

	.stats-item .subtitle {
		font-size: 16px;
		line-height: 20px;
		text-align: center;
	}

	/* descriptive-item */
	.descriptive-item {
		max-width: none;
	}

	.descriptive-item .title {
		font-size: 32px;
		line-height: 38px;
	}

	.descriptive-item .subtitle {
		font-size: 16px;
		line-height: 26px;
	}

	/* solution card */

	.solution-card {
		width: 100%;
		height: auto;
		background: #fafafa 0% 0% no-repeat padding-box;
		border-radius: 83px;
		padding: 32px;
		margin: 0;
	}

	.solution-card .title {
		font-size: 22px;
		line-height: 30px;
	}

	.solution-card .subtitle {
		font-size: 16px;
		line-height: 26px;
		margin-top: 16px;
	}

	.solution-card img {
		height: 234px;
	}

	/* how it works item */

	.how-it-works-item {
		font-weight: lighter;
	}

	.how-it-works-item .image {
		width: 156px;
		height: 156px;
	}

	.how-it-works-item img {
		object-fit: contain;
	}

	.how-it-works-item .title {
		margin-top: 15px;
	}

	/* system item */
	.system-item {
		font-size: 16px;
		line-height: 20px;
		text-align: center;
	}

	/* function item */
	.function-item {
		margin-top: 90px;
	}

	.function-item .title {
		font-weight: lighter;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		max-width: auto;
	}

	.function-item img {
		max-height: 50px;
		width: auto;
	}

	/* costs panel */

	.costs-panel {
		margin-top: 60px;
		margin-bottom: 0px;
	}

	.costs-panel .title {
		font-size: 32px;
		line-height: 38px;
		max-width: auto;
	}

	.costs-panel .summary {
		width: auto;
		height: auto;
		background: transparent;
	}

	.costs-panel .summary .button {
		color: #fff;
		background-color: #0055ff;
		width: 100%;
		height: 45px;
		border-radius: 35px;
		font-weight: bold;
		text-align: center;
		font-size: 12px;
	}

	.costs-panel .section-title {
		font-size: 14px;
		line-height: 30px;
	}

	/* cost panel item */

	.cost-panel-item {
		max-width: 340px;
	}

	.cost-panel-item .title {
		font-size: 14px;
		line-height: 17px;
	}

	.cost-panel-item .subtitle {
		font-size: 14px;
		line-height: 34px;
	}

	.cost-panel-item .input {
		width: 115px;
		height: 40px;
		border-radius: 42px;
		opacity: 1;
		text-align: center;
	}

	.cost-panel-item .input img {
		width: 40px;
	}

	.cost-panel-item .ico {
		/* max-height: 50px;
		width: auto; */
	}

	.cost-panel-item-new .title {
		font-size: 14px;
		line-height: 15px;
	}

	.cost-panel-item-new .subtitle {
		font-size: 14px;
		line-height: 15px;
	}

	/* nav bar */

	.navigation-bar {
		height: auto;
	}

	/* email input */
	.email-input {
		height: 45px;
		border-radius: 22.5px;
		border: 2px solid #fff;
	}

	.email-input input {
		min-width: auto;
		font-size: 14px;
	}

	.email-input .button {
		background-color: #fff;
		height: 100%;
		width: 190px;
		border-top-right-radius: 32px;
		border-bottom-right-radius: 32px;
		font-weight: bold;
		text-align: center;
		vertical-align: middle;
		font-size: 18px;
	}

	/* ul white row */
	ul.ul-white-row {
		display: block;
	}
	ul.ul-white-row li {
		text-align: left;
		display: list-item;
		list-style-image: url("./assets/tick_white_ico.svg");
		font-size: 10px;
		line-height: 10px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1640px !important;
	}
	.stats-item .title {
		font-size: 91px;
		line-height: 110px;
	}
}
